import { AnimatePresence } from 'framer-motion';
import ButtonBlur from '../buttons/closeButtonBlur';

interface ActionCarouselButtonsProps {
    handleScroll: (scrollTo: "prev" | "next") => void;
    isOpened: boolean;
    canScrollLeft: boolean;
    canScrollRight: boolean;
    handleClose: () => void;
}

const ActionCarouselButtons = ({
    handleClose,
    handleScroll,
    isOpened,
    canScrollLeft,
    canScrollRight,
}:ActionCarouselButtonsProps) => {
  return (
    <>
      <ButtonBlur
        handleClick={handleClose}
        isOpened={isOpened}
        icon="close"
        className="right-5 top-4"
      />
      <AnimatePresence>
        {canScrollLeft && (
          <ButtonBlur
            handleClick={() => handleScroll("prev")}
            isOpened={isOpened}
            icon="left"
            className="left-[20rem] top-[50%]"
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {canScrollRight && (
          <ButtonBlur
            handleClick={() => handleScroll("next")}
            isOpened={isOpened}
            icon="right"
            className="right-5 top-[50%]"
          />
        )}
      </AnimatePresence>
    </>
  );
};
export default ActionCarouselButtons;
