import { motion } from "framer-motion";
import {
  asideContentVariant,
  asideVariant,
} from "../carousel/carouselAnimation";
import { Project } from "types/projectTypes";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import ScrollIcon from '../scrollBar/ScrollIcon'
import {  useEffect, useRef, useState } from "react";
import { convertDropboxUrlToProxyUrl } from "utils/utilsFunctions";

interface DescriptionAsideProps {
  project: Project;
  isOpened: boolean;
}
const DescriptionAside = ({ project, isOpened }: DescriptionAsideProps) => {
  const cardRef = useRef<HTMLElement>(null);
  const [showScrollIcon, setShowScrollIcon] = useState(false);
  const logoFixedUrl = convertDropboxUrlToProxyUrl(project.logoUrl);

  let times: NodeJS.Timeout;

  useEffect(() => {
    if (isOpened) {
      times = setTimeout(() => {
        setShowScrollIcon(true);
      }, 2500);
    } else {
      setShowScrollIcon(false);
    }

    return () => {
      clearTimeout(times);
    };
  }, [isOpened]);
  return (
    <motion.aside
      variants={asideVariant}
      ref={cardRef}
      animate={isOpened ? "opened" : "closed"}
      initial="closed"
      className={`w-1/3 h-full bg-blue-500 relative shrink-0 no_scrollbar overflow-x-hidden ${isOpened ?'overflow-y-scroll' : 'overflow-y-hidden'}`}
      id="project-aside"
    >
      <header className="h-1/3 max-h-[120px] w-full flex justify-end relative ">
        <img
          className="h-full aspect-square"
          src={logoFixedUrl}
          alt="project logo"
        />
      </header>

      <motion.main
        variants={asideContentVariant}
        animate={isOpened ? "opened" : "closed"}
        initial="closed"
        className="text-end break-words text-pretty mr-6 "
      >
        <ReactMarkdown
          components={{
            p: ({ node, ...props }) => (
              <p className="text-3xl GOST" {...props} />
            ),
          }}
          remarkPlugins={[remarkBreaks]}
        >
          {project.title}
        </ReactMarkdown>

        <ReactMarkdown
          components={{
            p: ({ node, ...props }) => (
              <p
                className="text-xs font-extralight sm:text-base md:text-xl GOST"
                {...props}
              />
            ),
          }}
          remarkPlugins={[remarkBreaks]}
        >
          {project.description}
        </ReactMarkdown>
      </motion.main>
      {showScrollIcon && <ScrollIcon cardRef={cardRef} />}
    </motion.aside>
  );
};
export default DescriptionAside;
