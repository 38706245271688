import React, { useEffect, useState } from "react";
import { convertDropboxUrlToProxyUrl } from "utils/utilsFunctions";

interface ResponsiveImageProps {
  original_image: string;
  small_image: string;
  tiny_image: string;
  fallback_image: string;
  isVertical: boolean;
  onLoad?: () => void;
}

function ResponsiveImage({
  original_image,
  small_image,
  tiny_image,
  fallback_image,
  isVertical,
  onLoad,
}: ResponsiveImageProps) {
  const CORE_IMAGE_URL = "https://beyond-studio.co/media";

  const [imageSrc, setImageSrc] = useState(CORE_IMAGE_URL + tiny_image); // Start with the tiny image as a placeholder
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [closeBackground, setCloseBackground] = useState(false);
  const fixedFallbackUrl = convertDropboxUrlToProxyUrl(fallback_image);

  useEffect(() => {
    if (isLoading) return;
    const timeout = setTimeout(() => {
      setCloseBackground(true);
      onLoad && onLoad();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleImageLoad = () => {
    setIsLoading(false); // Hide the placeholder once the image has loaded
  };

  const handleImageError = () => {
    setIsError(true);

    setImageSrc(fixedFallbackUrl); // Use the fallback image if the main image fails to load
  };

  return (
    <>
      {!isError ? (
        <div
          key={imageSrc}
          style={{
            width: "100%",
            height: "100%",
            backgroundImage: closeBackground
              ? ""
              : `url(${CORE_IMAGE_URL + tiny_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            backgroundColor: "#fafafa",
          }}
        >
          <img
            srcSet={`${CORE_IMAGE_URL + small_image} 768w, ${
              CORE_IMAGE_URL + original_image
            } 769w`}
            src={imageSrc}
            alt="Responsive content"
            onLoad={handleImageLoad}
            onError={handleImageError}
            style={{
              width: "100%",
              height: "100%",
              objectFit: isVertical ? "contain" : "cover",
              position: "absolute",
              top: 0,
              left: 0,
              opacity: isLoading ? 0 : 1,
              transition: "opacity 0.5s ease-in-out",
            }}
          />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundImage: closeBackground ? "" : `url(./b-logo.jpeg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            backgroundColor: "#fafafa",
          }}
        >
          <img
            src={fixedFallbackUrl}
            alt="Responsive content"
            onLoad={handleImageLoad}
            style={{
              width: "100%",
              height: "100%",
              objectFit: isVertical ? "contain" : "cover",
              position: "absolute",
              top: 0,
              left: 0,
              opacity: isLoading ? 0 : 1,
              transition: "opacity 0.5s ease-in-out",
            }}
          />
        </div>
      )}
    </>
  );
}

export default ResponsiveImage;
