import {
  motion,
  useInView,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";
import { useLazyGetProjectContentQuery } from "store/api/projectApi";
import { useEffect, useRef, useState } from "react";
import { ContentReturnTypes, Project } from "types/projectTypes";
import HorizontalCarouselView from "./horizontalCarouselView";
import { getItemType, TextElement } from "./carouselElements/elements";
import useMobile from "hooks/useMobile";

function HorizontalCarousel({
  project,
  handleStartAnimate,
}: {
  project: Project;
  handleStartAnimate?: () => void;
}) {
  //refs=======================
  const projectRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(projectRef);
  const baseImageRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  //state=======================
  const [content, setContent] = useState<ContentReturnTypes[]>([]);
  const [isOpened, setIsOpened] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [sideMove, setSideMove] = useState(0);
  const isMobile = useMobile();
  const { scrollXProgress, scrollX } = useScroll({ container: carouselRef });
  const [elementIndex, setElementIndex] = useState(0);
  const [xProgress, setXProgress] = useState(0);

  const lastScrollX = useRef(0);

  useMotionValueEvent(scrollXProgress, "change", (latest) => {
    if (!isOpened && !isMobile) {
      setXProgress(0);
      return;
    }
    setXProgress(latest);

    lastScrollX.current = latest;
  });

  //api=======================
  const [getProjectContent, { data }] = useLazyGetProjectContentQuery();
  //effects=======================

  useEffect(() => {
    if (isInView) {
      getProjectContent(project.id).then(({ data }) => {
        if (!data) return;
        if (handleStartAnimate) handleStartAnimate();
        setContent([...data]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView]);

  useEffect(() => {
    if (!isOpened) return;
    handleScrollCheck(carouselRef);
    let timeout: NodeJS.Timeout;

    timeout = setTimeout(() => {
      projectRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpened]);

  useEffect(() => {
    carouselRef.current?.addEventListener(
      "scroll",
      handleScrollCheck.bind(null, carouselRef)
    );

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      carouselRef.current?.removeEventListener(
        "scroll",
        handleScrollCheck.bind(null, carouselRef)
      );
    };
  }, [carouselRef]);

  useEffect(() => {
    if (!content.length) {
      setElementIndex(0);
      return;
    }
    const index = Math.round(xProgress * (content.length + 1));
    setElementIndex(index);
  }, [xProgress, content.length]);

  //functions=======================
  const handleClick = () => {
    if (!data) return;
    const _toOpen = !isOpened;
    if (!_toOpen) {
      baseImageRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setTimeout(() => {
        setIsOpened(false);
      }, 500);
    } else {
      setIsOpened(_toOpen);
    }
  };

  const handleScroll = (direction: "prev" | "next") => {
    const scrollContainer = carouselRef.current;
    if (!scrollContainer) return;

    const scrollPosition = scrollContainer.scrollLeft;
    // const scrollWidth = scrollContainer.clientWidth;

    // Find the next or previous image based on the scroll position
    const childrenArray = Array.from(
      scrollContainer.children
    ) as HTMLDivElement[];
    let targetIndex = childrenArray.findIndex((child) => {
      return child.offsetLeft > scrollPosition;
    });

    const targetElement = childrenArray[targetIndex];

    if (targetElement) {
      const scrollPosition = direction === "prev" ? -1 : 1;
      const scrollBy = targetElement.clientWidth * scrollPosition;

      scrollContainer.scrollBy({
        left: scrollBy,
        behavior: "smooth",
        top: 0,
      });
    }
  };

  const handleScrollCheck = (refCheck: React.RefObject<HTMLDivElement>) => {
    if (!refCheck.current) return;

    const scrollLeft = refCheck.current.scrollLeft;
    const scrollWidth = refCheck.current.scrollWidth;
    const clientWidth = refCheck.current.clientWidth;
    const tolerance = 2; // Allow a small tolerance (2 pixels)

    if (scrollLeft <= 0) {
      setCanScrollLeft(false);
    } else {
      setCanScrollLeft(true);
    }

    if (scrollLeft >= scrollWidth - clientWidth - tolerance) {
      setCanScrollRight(false);
    } else {
      setCanScrollRight(true);
    }
  };
  useEffect(() => {
    if (!canScrollLeft) {
      setSideMove(0);
    }
    if (canScrollLeft && canScrollRight) {
      setSideMove(1);
    }
    if (!canScrollRight && canScrollLeft) {
      setSideMove(2);
    }
  }, [canScrollLeft, canScrollRight]);

  return (
    <div>
      <HorizontalCarouselView
        project={project}
        isOpened={isOpened || isMobile}
        handleClick={handleClick}
        projectRef={projectRef}
        baseImageRef={baseImageRef}
        carouselRef={carouselRef}
        handleScroll={handleScroll}
        canScrollLeft={canScrollLeft}
        canScrollRight={canScrollRight}
        isMobile={isMobile}
      >
        <>
          {isMobile && (
            <TextElement
              item={{
                title: project.title,
                description: project.description,
                id: project.id,
              }}
              isMobile
              isOpened={true}
            />
          )}

          {content
            .sort((a, b) => a.order - b.order)
            .map((item, i) => {
              return getItemType(
                item.display_type,
                item,
                isOpened || isMobile,
                isMobile,
                i
              );
            })}
        </>
      </HorizontalCarouselView>
      {isMobile && (
        <Indicators
          isInView={isInView}
          length={content.length}
          xProgress={xProgress}
          elementIndex={elementIndex}
        />
      )}
    </div>
  );
}

export default HorizontalCarousel;

const Indicators = ({
  isInView,
  length,
  xProgress,
  elementIndex,
}: {
  isInView: boolean;
  length: number;
  xProgress: number;
  elementIndex: number;
}) => {
  return (
    <>
      <div className="w-full h-8 flex justify-center items-center overflow-y-hidden">
        <motion.div
          initial={{ y: 0 }}
          animate={
            isInView
              ? { y: 0, transition: { duration: 0.5 } }
              : { y: 50, transition: { duration: 0.3 } }
          }
          className="w-full m-0 p-0   flex justify-center rounded-none  h-4   gap-1"
        >
          <motion.div
            className={`h-full overflow-hidden  
               w-[3.5rem] pl-2
             `}
          >
            <motion.div
              style={{ translateX: xProgress * -100 + "%" }}
              className=" w-fit join  flex justify-start  ml-4   rounded-none  h-full"
            >
              {Array.from({ length: length + 2 }).map((_, i) => (
                <div
                  className={`join-horizontal h-full min-w-4 w-4 bg-black   transition-all duration-500 ${
                    elementIndex === i
                      ? " scale-100 opacity-100"
                      : "opacity-70 scale-75"
                  } `}
                ></div>
              ))}
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
};
