import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import useMobile from "hooks/useMobile";

const words = ["IMAGINATION", "EXPECTATION", "VISION"];

const WordAnimation = () => {
  const [index, setIndex] = React.useState(0);
  const [hover, setHover] = React.useState(false);
  const isMobile = useMobile();

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!hover && Math.random() > 0.6) {
        setHover(true);
        setTimeout(() => {
          setHover(false);
        }, 3000);

        return;
      }

      setIndex((prev) => (prev + 1) % words.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="flex flex-col md:flex-row pt-1 items-center justify  center h-full text-nowrap pl-2 w-1/3 min-w-[30%] max-w-[30%]  "
    >
         <img
  className="inline-block h-1/2 "
  src="./beyond-clear.png"
  alt="letter b"
/>
      
       <div className="text-sm pt-1 -ml-2 md:ml-0 md:text-4xl font-bold flex flex-col md:flex-row shrink-0  md:min-w-[450px]   w-24 md:w-fit items-center text-white">

        <AnimatePresence mode="wait">
          {hover ? (
            <motion.span
              initial={{ opacity: 0, y: 0, translateX: "50%" }}
              animate={{ opacity: 1, translateX: 0 }}
              transition={{ delay: 0.4, duration: 0.4, ease: "easeOut" }}
              className="inline-block ml-2 mt-1  text-black GOST-S1 shrink-0 text-xs md:text-4xl"
            >
               STUDIO
            </motion.span>
          ) : (
            <AnimatePresence mode="wait">
              <motion.span
                key={words[index]}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="inline-block ml-1 mt-1  text-base-content shrink-0 GOST-S1 text-xs md:text-4xl"
              >
                {words[index]}
              </motion.span>
            </AnimatePresence>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default WordAnimation;
