import LandingPage from "pages/landingPage";
import "./App.css";
import { motion, AnimatePresence } from "framer-motion";
import { useRef, useState } from "react";

import WordFlipper from "components/animation/wordFlipper";

export default function App() {
  const [viewContent, setViewContent] = useState(false);
  const startRef = useRef<HTMLDivElement>(null);
  const [toAnimate, setToAnimate] = useState(false);


  const handleShowContent = ()=>{
    setViewContent(true);
  }

  const handleStartAnimate = () => {
    setToAnimate(true);
  };

  return (
    <div ref={startRef} className="m-0 p-0">
      <AnimatePresence>
        {(!viewContent || !toAnimate) && (
          <motion.div
            className="h-[100dvh] w-full bg-black fixed z-[2000]"
            initial={{ scaleY: 1, opacity: 1 }}
            exit={{ scaleY: 0, opacity: 0, transition: { duration: 1, delay:0.8 } }}
            transition={{ duration: 1, ease: "easeInOut" }}
            style={{ transformOrigin: "bottom" }}
          >
             <WordFlipper handleShowContent={handleShowContent} words={["IMAGINATION", "EXPECTATION", "VISION", "STUDIO"]} canStart={viewContent} delay={1} />
          </motion.div>
        )}
      </AnimatePresence>
      <LandingPage
        isShown={viewContent}
        handleStartAnimate={handleStartAnimate}
      />
    </div>
  );
}

