import React, { useState, useEffect } from "react";
import { motion, AnimatePresence, stagger } from "framer-motion";

const WordFlipper = ({
  words,
  delay = 1,
  canStart = false,
  handleShowContent,
}: {
  words: string[];
  delay: number;
  canStart: boolean;
  handleShowContent: () => void;
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [counter, setCounter] = useState(words.length);
  const [isComplete, setIsComplete] = useState(false);
  const finalText = "architecture // interior design ";

  useEffect(() => {
    if (isComplete) {
      setTimeout(() => {
        handleShowContent();
      }, 2000);
    }
  }, [isComplete]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === words.length - 1 ? prevIndex : prevIndex + 1
      );
    }, delay * 1000);

    return () => clearInterval(interval);
  }, [words, delay]);

  const flipVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        // staggerChildren: 0.1, // Stagger each letter
      },
    },
    exit: {
      // rotateY: -180,
      opacity: 0.6,
      transition: {
        duration: 0.9,
      },
    },
  };

  const letterVariants = {
    hidden: {
      rotateY: 90,
      opacity: 0,
    },
    visible: {
      rotateY: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      rotateY: -90,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
  };
  const windowVariant = {
    open: {
      opacity: 0,
      translateY: "-100%",
      width: 0,
      transition: {
        duration: 0.7,
      },
    },
    close: {},
  };

  const handleComplete = () => {
    if (counter <= 0) {
      setIsComplete(true);
    }
    setCounter((prev) => prev - 1);
  };

  return (
    <motion.div
      variants={windowVariant}
      initial="close"
      animate={canStart ? "open" : "close"}
      exit={{opacity:0, transition:{duration:0.5}}}
      className=" bg-black h-[100dvh] overflow-hidden  w-full flex items-center  justify-center"
      style={{ display: "flex", justifyContent: "center", fontSize: "2rem" }}
    >
      <img
        src="./beyond-logo-small.png"
        alt="Beyond Studio Logo"
        className="absolute top-[30%] left-[50%] shrink-0    translate-x-[-54%] translate-y-[-50%] w-1/2 min-w-[400px]   m-4"
      />
      <AnimatePresence mode="sync">
        <motion.div
          key={words[currentIndex]}
          variants={flipVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className="  text-white absolute top-1/2   "
          style={{ display: "flex" }}
          onAnimationComplete={handleComplete}
        >
          {words[currentIndex].split("").map((letter, index) => (
            <motion.h1
              key={index}
              variants={letterVariants}
              className=" text-4xl sm:text-5xl md:text-[5rem]   2xl:text-[8rem] GOST"
              style={{ display: "inline-block", margin: "0 2px" }}
            >
              {letter}
            </motion.h1>
          ))}
        </motion.div>
      </AnimatePresence>
      <AnimatePresence>
        {isComplete && (
          <motion.div
            key="finalText"
            variants={flipVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            className=" text-white absolute  bottom-2 top-[70%] overflow-hidden  "
            style={{ display: "flex" }}
          >
            {finalText.split("").map((letter, index) => (
              <motion.h1
                key={index}
                variants={letterVariants}
                className=" text-xs sm:text-2xl md:text-3xl GOST break-words"
                style={{ display: "inline-block", margin: "0 2px" }}
              >
                {letter}
              </motion.h1>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default WordFlipper;
