import { motion } from "framer-motion";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const PickerAnimation = ({visible}:{visible:boolean}) => {
  
  return(
  <div className="h-full w-full flex justify-start items-center">
    <motion.ul
      className="container  "
      variants={container}
      // initial="hidden"
      animate={visible?"visible":"hidden"}
    >
      {[0, 1, 2, 3].map((index) => (
        <motion.li key={index} className={`item w-2 h-2 ${visible?'opacity-100':'opacity-0'}`}  variants={item} />
      ))}
    </motion.ul>
  </div>
)}

export default PickerAnimation;
