export const projectVariant = {
    opened: {
      height: "70vh",
      transition: {
        duration: 1,
        delay: 0.5,
      },
    },
    closed: {
      height: "33vh",
      
    },
  };
  

  export const asideVariant = {
    opened: {
      width: "20rem",
      backgroundColor: "white",
      boxShadow: "0 0 10px 10px white",
      transition: {
        duration: 1,
        delay: 0.5,
      },
    },
    closed:{
      width: "33%",
      backgroundColor: "transparent",
     
      transition: {
        duration: 0,
      },
    }
  };

  export const contentVariant = {
    opened: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,

        ease: "easeInOut",
      },
    },
    closed: {
      x: "-50%",
      opacity: 0,
      transition: {
        delay: 0.5,
        duration: 0.4,
      },
    },
  };

  export const asideContentVariant = {
    opened: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.5,
        ease: "easeInOut",
      },
    },
    closed: {
      x: "-50%",
      opacity: 0,
    },
  };

  export const closeVariant = {
    opened: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.5,
      },
    },
    closed: {
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
  };