import { lazy, Suspense } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Project } from "types/projectTypes";
import { useGetProjectsQuery } from "store/api/projectApi";
import { useEffect, useRef, useState } from "react";
import NavBar from "components/header/navBar";
import CarouselWrapper from "components/carousel/carouselWrapper";
import useMobile from "hooks/useMobile";
const ContactPage = lazy(() => import("./contactPage"));
const AboutPage = lazy(() => import("./aboutPage"));

const LandingPage = ({
  isShown,
  handleStartAnimate,
}: {
  isShown: boolean;
  handleStartAnimate: () => void;
}) => {
  const { data, error } = useGetProjectsQuery(null);
  const [projects, setProjects] = useState<Project[]>([]);
  const [animationStarted, setAnimationStarted] = useState(false);
  const [viewIndex, setViewIndex] = useState(0);
  const [transitionAnimation, setTransitionAnimation] = useState(false);
  let timeout: NodeJS.Timeout;
  const navRef = useRef<HTMLDivElement>(null);
  const isMobile = useMobile();
  const [oisOverflown, setOisOverflown] = useState(false);

  useEffect(() => {
    if (data) {
      const projConverted: Project[] = data.map((proj) => ({
        id: proj.id,
        title: proj.title,
        description: proj.description,
        baseImageUrl: proj.base_img_url,
        logoUrl: proj.logo_img_url,
        project_order: proj.project_order || -1,
      }));
      setProjects([...projConverted]);
    }
    if (error) {
      console.log(error);
    }
  }, [data, error]);

  useEffect(() => {
    if (isShown) {
      setAnimationStarted(true);
      if (!isMobile) return;

      setTimeout(() => {
        setOisOverflown(true);
      }, 3000);
    }
  }, [isShown]);

  const handleViewChange = (index: number) => {
    setViewIndex(index);
  };
  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
    setTransitionAnimation(true);
    timeout = setTimeout(() => {
      navRef.current?.scrollIntoView({ behavior: "instant" });
      setTransitionAnimation(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [viewIndex]);

  return (
    <div
      id="landingPage"
      className={`w-full relative ${
        isMobile && !oisOverflown && "overflow-hidden"
      } ${
        !isShown
          ? "h-full"
          : "h-full pt-[10vh] pb-[10vh] sm:pt-[30vh] sm:pb-[30vh]"
      }`}
    >
      <NavBar onViewChange={handleViewChange} />
      <div ref={navRef} />
      <AnimatePresence mode="sync">
        {transitionAnimation && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={transitionAnimation && { opacity: 1 }}
              transition={{ duration: 0.8 }}
              exit={{ opacity: 0 }}
              className=" w-full h-full top-0 z-[1001] fixed  "
            >
              <motion.div className="absolute top-1/2 left-1/2  translate-x-[-50%] translate-y-[-50%] ">
                <img
                  src="./white-beyond-full.png"
                  alt="Beyond"
                  className="w-full h-full object-contain animate-pulse  "
                />
              </motion.div>
            </motion.div>

            <motion.div
              key={transitionAnimation ? "transition-true" : "transition-false"} // Convert to a string
              initial={{ y: "100%", scaleY: 0 }}
              animate={transitionAnimation ? { y: "0", scaleY: "150%" } : {}}
              transition={{ duration: 1.5 }}
              exit={{ y: "100%", scaleY: 0 }}
              className="bg-black w-full h-screen top-0 bottom-0 z-[1000] fixed"
            />
          </>
        )}
      </AnimatePresence>
      <AnimatePresence mode="sync">
        {viewIndex === 1 && (
          <motion.div
            key={1}
            className=""
            initial={{ display: "none" }}
            animate={{ display: "block" }}
            transition={{ delay: 1.5, duration: 0 }}
            exit={{ display: "none" }}
          >
            <Suspense fallback={<></>}>
              {" "}
              <AboutPage />
            </Suspense>
          </motion.div>
        )}
        {viewIndex === 2 && (
          <motion.div
            key={2}
            className=""
            initial={{ display: "none" }}
            animate={{ display: "block" }}
            transition={{ delay: 1.5, duration: 0 }}
            exit={{ display: "none" }}
          >
            <Suspense fallback={<></>}>
              <ContactPage />
            </Suspense>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        initial={{ display: "block" }}
        animate={
          viewIndex !== 0
            ? { display: "none", transition: { delay: 1.5, duration: 0 } }
            : { display: "block", transition: { delay: 1.5, duration: 0 } }
        }
      >
        <CarouselWrapper
          projects={projects}
          animationStarted={animationStarted}
          handleStartAnimate={handleStartAnimate}
        />
      </motion.div>
    </div>
  );
};

export default LandingPage;
