import { AnimatePresence, motion, useInView } from "framer-motion";
import { ContentReturnTypes } from "types/projectTypes";
import { contentVariant } from "../carouselAnimation";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import ResponsiveImage from "components/img/responsiveImage";
import useMobile from "hooks/useMobile";
import ScrollIcon from "../../scrollBar/ScrollIcon";
import { MdTouchApp } from "react-icons/md";
interface BasicElementProps {
  item: Partial<ContentReturnTypes>;
  isOpened: boolean;
  isSmall?: boolean;
}

interface VideoElementProps {
  url: string;
  isMobile?: boolean;
}

export const VideoElement = ({ url, isMobile }: VideoElementProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isInView = useInView(videoRef, { once: false });

  if (isMobile && videoRef.current) {
    if (isInView) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }

  return (
    <video
      ref={videoRef}
      src={url}
      autoPlay={true}
      loop
      muted
      playsInline
      className="w-full h-full object-cover snap-start "
    />
  );
};

interface mobileTextProps extends BasicElementProps {
  isMobile?: boolean;
  isSelf?: boolean;
}

export const TextElement = ({
  item,
  isOpened,
  isMobile,
  isSelf,
}: mobileTextProps) => {
  const elemRef = useRef<HTMLDivElement>(null);
  const [showScrollIcon, setShowScrollIcon] = useState(false);
  useEffect(() => {
    if (isOpened) {
      setTimeout(() => {
        setShowScrollIcon(true);
      }, 2500);
    } else {
      setShowScrollIcon(false);
    }
  }, [isOpened]);
  return (
    <motion.div
      ref={elemRef}
      variants={contentVariant}
      animate={isOpened ? "opened" : "closed"}
      initial="closed"
      exit="closed"
      key={item.id}
      className={`h-full min-w-[33vh] aspect-video shrink-0 flex w-1/4 no_scrollbar overflow-y-scroll relative  snap-start `}
      id="project-content"
    >
      <div className={`${isMobile ? "p-4 pl-10" : "p-2"}`}>
        <h3 className={`text-lg sm:text-xl md:text-3xl GOST`}>{item.title}</h3>
        <ReactMarkdown
          components={{
            p: ({ node, ...props }) => (
              <p className="text-xs sm:text-base md:text-xl GOST" {...props} />
            ),
          }}
          remarkPlugins={[remarkBreaks]}
        >
          {item.description}
        </ReactMarkdown>
      </div>
      {showScrollIcon && <ScrollIcon cardRef={elemRef} />}
    </motion.div>
  );
};

export const BasicElement = ({
  item,
  isOpened,
  isSmall,
}: BasicElementProps) => {
  return (
    <motion.div
      variants={contentVariant}
      animate={isOpened ? "opened" : "closed"}
      initial="closed"
      exit="closed"
      key={item.id}
      className={`h-full min-w-[33vh] snap-start aspect-video shrink-0 ${
        isSmall ? "w-1/2" : ""
      }  snap-start`}
      id="project-content"
    >
      <ResponsiveImage
        isVertical={isSmall || false}
        original_image={item.original_image || ""}
        small_image={item.small_image || ""}
        tiny_image={item.tiny_image || ""}
        fallback_image={item.image_url || ""}
      />
    </motion.div>
  );
};

interface BasicTextElementProps extends BasicElementProps {
  isMobile?: boolean;
}

export const BasicTextElement = ({
  item,
  isOpened,
  isMobile,
}: BasicTextElementProps) => {
  return (
    <>
      <div
        id="text-element"
        className="h-full  min-w-[33vh] aspect-video shrink-0 flex w-1/4 overflow-visible z-[1]  sm:pl-6 md:pl-10"
      >
        <TextElement item={item} isOpened={isOpened} isMobile={isMobile} />
      </div>
      <motion.div
        variants={contentVariant}
        animate={isOpened ? "opened" : "closed"}
        initial="closed"
        exit="closed"
        key={item.id}
        className="h-full min-w-[33vh] aspect-video shrink-0 flex w-3/4   snap-start"
        id="project-content"
      >
        <ResponsiveImage
          isVertical={item.is_vertical_img || false}
          original_image={item.original_image || ""}
          small_image={item.small_image || ""}
          tiny_image={item.tiny_image || ""}
          fallback_image={item.image_url || ""}
        />
      </motion.div>
    </>
  );
};

interface BasicHoverElementProps extends BasicElementProps {
  withHover: boolean;
}

export const BasicHoverElement = ({
  item,
  isOpened,
  withHover,
}: BasicHoverElementProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const isMobile = useMobile();

  return (
    <motion.div
      variants={contentVariant}
      animate={isOpened ? "opened" : "closed"}
      initial="closed"
      exit="closed"
      key={item.id}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      onClick={() => isMobile && setIsHovered(!isHovered)}
      className="h-full min-w-[33vh] aspect-video shrink-0 relative snap-start"
      id="project-content"
    >
      <ResponsiveImage
        onLoad={() => setIsImgLoaded(true)}
        isVertical={item.is_vertical_img || false}
        original_image={item.original_image || ""}
        small_image={item.small_image || ""}
        tiny_image={item.tiny_image || ""}
        fallback_image={item.image_url || ""}
      />
      {withHover && isImgLoaded && (
        <>
          {isMobile &&!isHovered && (
            <div className="absolute w-10 h-10 z-50 bottom-0 left-0  ">
              <MdTouchApp className="text-black text-4xl" />
            </div>
          )}

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: isHovered ? 0.5 : 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="absolute w-full h-full left-0 top-0 bg-black"
          ></motion.div>
        </>
      )}
      <div
        className={`absolute w-full h-full overflow-y-auto  left-0 ${
          isMobile ? "top-0" : "top-1/2 -translate-y-1/4"
        }   `}
      >
        <AnimatePresence mode="sync">
          {(isHovered || !withHover) && isImgLoaded && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              exit={{ opacity: 0, y: -20 }}
              style={{ width: "calc(100% - 8rem)" }}
              className={`${
                withHover
                  ? "text-white backdrop-brightness-110"
                  : "text-zinc-800 backdrop-brightness-110 "
              }`}
            >
              <h3
                className={`text-xl sm:text-3xl md:text-5xl GOST  text-center font-semibold text-nowrap`}
              >
                {item.title}
              </h3>
              <p
                className={`p-2 text-sm sm:text-xl  text-center md:text-2xl  GOST `}
              >
                {item.description}
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

type ItemType =
  | "Video"
  | "Text"
  | "Basic"
  | "Basic Text"
  | "Basic Hover"
  | "Side Hero"
  | "Reveal";

export const getItemType = (
  type: ItemType,
  item: ContentReturnTypes,
  isOpened: boolean,
  isMobile: boolean,
  key:any,
) => {
  switch (type) {
    case "Video":
      return <VideoElement key={key} url={item.image_url} isMobile={isMobile} />;
    case "Text":
      return <TextElement key={key} isSelf={true} item={item} isOpened={isOpened} />;
    case "Basic":
      return (
        <BasicElement
        key={key}
          isSmall={item.is_vertical_img}
          item={item}
          isOpened={isOpened}
        />
      );
    case "Basic Text":
      return (
        <BasicHoverElement key={key} item={item} isOpened={isOpened} withHover={false} />
      );

    case "Basic Hover":
      return (
        <BasicHoverElement  key={key}item={item} isOpened={isOpened} withHover={true} />
      );

    case "Side Hero":
      return <BasicTextElement key={key} item={item} isOpened={isOpened} />;

    case "Reveal":
      return <></>;
    default:
      return <BasicElement key={key} item={item} isOpened={isOpened} />;
  }
};
